<template>
  <v-dialog v-model="dialog" persistent max-width="700px" origin="left top">
    <v-card>
      <v-card-title> Editar Conta a Pagar </v-card-title>

      <v-card-text>
        <h3 class="mt-2 mb-2">Detalhes</h3>
        <v-row>
          <v-col>
            <company-select v-model="form.company" />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><app-text-field label="Descrição" v-model="form.description" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <supplier-search v-model="form.supplier" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <account-plan-search v-model="form.account_plan" />
            <!-- <app-text-field
              label="Plano de Contas"
              v-model="form.account_plan_id"
            /> -->
          </v-col>
          <v-col>
            <cost-center-search v-model="form.cost_center" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.details"
              label="Detalhes"
              hide-details="auto"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="align-center">
          <v-col >
            <app-text-field v-model="form.document" label="Documento" />
          </v-col>
          <v-col  >
            <app-number-field v-model="form.value" label="Valor" />
          </v-col>
          <v-col >
            <app-date-field
              v-model="form.due_date"
              label="Vencimento"
              type="date"
            />
          </v-col>
          <v-col  >
            <app-date-field
              v-model="form.due_date_business_day"
              label="Vencimento (Dia Útil)"
              type="date"
            />
          </v-col>
        </v-row>

        <h3 class="mt-8 mb-2">Situação</h3>
        <v-row>
          <v-col>
            <v-chip-group v-model="form.status" mandatory>
              <v-chip value="foreseen" color="secondary" small filter>
                Previsão
              </v-chip>
              <v-chip value="to_pay" color="primary" small filter>
                A Pagar
              </v-chip>
              <v-chip v-if="$acl.can('billPayableCancel')" value="canceled" color="error" small filter>
                Cancelada
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <!-- <v-divider vertical></v-divider> -->
        <!-- <v-col class="ml-8">
            <h3 class="mb-2">Parcelas</h3>
            <bill-payable-form-parcels v-model="form.parcels" />
          </v-col> -->
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch";
import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch";
import CostCenterSearch from "@/components/cost-center/ui/CostCenterSearch";
import BillPayableFormParcels from "@/components/bill-payable/forms/BillPayableFormParcels";

export default {
  components: {
    CompanySelect,
    SupplierSearch,
    AccountPlanSearch,
    CostCenterSearch,
    BillPayableFormParcels,
  },

  data() {
    return {
      dialog: false,
      form: null,
      baseForm: {
        company: null,
        supplier: null,
        description: null,
        account_plan: null,
        cost_center: null,
        details: null,
        document: null,
        value: 0,
        due_date: null,
        due_date_business_day: null,
        status: "to_pay",
      },
    };
  },

  created() {
    this.reset();
  },

  methods: {
    async open(id) {
      this.reset();
      await this.loadBillPayable(id);
      this.dialog = true;
    },

    async loadBillPayable(id) {
      let response = await this.$http.show("bill-payable/bill-payable", id);

      this.form = response.billPayable
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.store().then(() => {
        this.$emit("store");
        this.dialog = false;
      });
    },

    async store() {
      return new Promise(async (resolve, reject) => {
        this.$store.commit("app/showLoading");
        await this.$http
          .update("bill-payable/bill-payable", this.form.id, this.form)
          .then((response) => {
            resolve();
          }).catch((error) => {

          })
        this.$store.commit("app/hideLoading");
      });
    },
  },
};
</script>
