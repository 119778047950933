<template>
  <v-dialog v-model="dialog" persistent max-width="1400px" origin="left top">
    <v-card>
      <v-card-title> Novo Conta a Pagar </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="mr-8">
            <h3 class="mt-2 mb-2">Detalhes</h3>
            <v-row>
              <v-col>
                <company-select v-model="form.company" />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                ><app-text-field label="Descrição" v-model="form.description" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <supplier-search v-model="form.supplier" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <account-plan-search v-model="form.account_plan" />
                <!-- <app-text-field
              label="Plano de Contas"
              v-model="form.account_plan_id"
            /> -->
              </v-col>
              <v-col>
                <cost-center-search v-model="form.cost_center" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="form.details"
                  label="Detalhes"
                  hide-details="auto"
                  dense
                  outlined
                />
              </v-col>
            </v-row>

            <h3 class="mt-8 mb-2">Situação</h3>
            <v-row>
              <v-col>
                <v-chip-group v-model="form.status" mandatory>
                  <v-chip value="foreseen" color="secondary" small filter>
                    Previsão
                  </v-chip>
                  <v-chip value="to_pay" color="primary" small filter>
                    A Pagar
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="ml-8">
            <h3 class="mb-2">Parcelas</h3>
            <bill-payable-form-parcels v-model="form.parcels" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
        <v-btn
          @click="handleSaveAndContinue()"
          class="text-capitalize"
          color="primary"
          >Salvar e continuar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch";
import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch";
import CostCenterSearch from "@/components/cost-center/ui/CostCenterSearch";
import BillPayableFormParcels from "@/components/bill-payable/forms/BillPayableFormParcels";

export default {
  components: {
    CompanySelect,
    SupplierSearch,
    AccountPlanSearch,
    CostCenterSearch,
    BillPayableFormParcels,
  },

  data() {
    return {
      dialog: false,
      form: null,
      baseForm: {
        company: null,
        supplier: null,
        description: null,
        account_plan: null,
        cost_center: null,
        details: null,
        parcels: [
          {
            document: null,
            value: 0,
            due_date: null,
          },
        ],
        status: "to_pay",
      },
    };
  },

  created() {
    this.reset();
  },

  methods: {
    open(data = null) {
      this.reset();
      
      if (data) {
        this.form = data
      }
      
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.store().then(() => {
        this.$emit("store");
        this.dialog = false;
      });
    },

    handleSaveAndContinue() {
      this.store().then(() => {
        this.$emit("store");
        this.reset();
      });
    },

    async store() {
      return new Promise(async (resolve, reject) => {
        this.$store.commit("app/showLoading");
        await this.$http
          .store("bill-payable/bill-payable", this.form)
          .then((response) => {
            resolve();
          })
          .catch((error) => {});
        this.$store.commit("app/hideLoading");
      });
    },
  },
};
</script>
