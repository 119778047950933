<template>
  <v-dialog v-model="dialog" max-width="1000px" eager origin="center">
    <v-form ref="form">
      <v-card>
        <v-card-title> Conta A Pagar </v-card-title>
        <v-card-text>
          <h3 class="mt-2 mb-2">Detalhes</h3>
          <v-row>
            <v-col>
              <small class="text-secondary">Empresa</small>
              <br />
              <span class="text--primary"> {{ billPayable.company.name }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <small class="text-secondary">Descrição</small>
              <br />
              <span class="text--primary"> {{ billPayable.description }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <small class="text-secondary">Plano de contas</small>
              <br />
              <span class="text--primary">
                {{ billPayable.account_plan.name }}</span
              >
            </v-col>
          </v-row>
          <v-row v-if="billPayable.details">
            <v-col>
              <small class="text-secondary">Detalhes</small>
              <br />
              <span class="text--primary" style="white-space: pre;">
                {{ billPayable.details }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <small class="text-secondary">Documento</small>
              <br />
              <span class="text--primary"> {{ billPayable.document }}</span>
            </v-col>
            <v-col>
              <small class="text-secondary">Vencimento</small>
              <br />
              <span class="text--primary">
                <app-due-date-label :due-date="billPayable.due_date"
              /></span>
            </v-col>
            <v-col>
              <small class="text-secondary">Valor</small>
              <br />
              <span class="text--primary">
                {{ $format.decimal(billPayable.value) }}</span
              >
            </v-col>
            <v-col>
              <br />
              <bill-payable-status-chip :value="billPayable.status" />
            </v-col>
          </v-row>
          <h3 class="mt-6">Pagamentos</h3>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="billPayable.bill_payable_payments"
                hide-default-footer
                disable-sort
              >
                <template v-slot:[`body.append`]>
                  <tr>
                    <td>Totais:</td>
                    <td></td>
                    <td class="text-right">
                      <b>{{ $format.decimal(billPayable.paid_value) }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ $format.decimal(billPayable.discount) }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ $format.decimal(billPayable.addition) }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ $format.decimal(billPayable.interest) }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ $format.decimal(billPayable.net_paid_value) }}</b>
                    </td>
                    <td></td>
                  </tr>
                </template>
                <template v-slot:[`item.account_transaction.account.name`]="{ item }">
                  <AccountLabel :account="item.account_transaction.account "/>
                </template>
                <!-- <template v-slot:[`item.company`]="{ item }">
                  <company-chip :company="item.company" />
                </template>
                <template v-slot:[`item.supplier_description`]="{ item }">
                  {{ item.description }}
                  <span v-if="item.supplier">| {{ item.supplier.name }}</span>
                </template>-->
                <template v-slot:[`item.paid_at`]="{ item }">
                  {{ $format.dateBr(item.paid_at) }}
                </template>
                <template v-slot:[`item.paid_value`]="{ item }">
                  {{ $format.decimal(item.paid_value) }}
                </template>
                <template v-slot:[`item.discount`]="{ item }">
                  {{ $format.decimal(item.discount) }}
                </template>
                <template v-slot:[`item.addition`]="{ item }">
                  {{ $format.decimal(item.addition) }}
                </template>
                <template v-slot:[`item.interest`]="{ item }">
                  {{ $format.decimal(item.interest) }}
                </template>
                <template v-slot:[`item.net_paid_value`]="{ item }">
                  {{ $format.decimal(item.net_paid_value) }}
                </template>
                <!-- <template v-slot:[`item.due_date`]="{ item }">
                  <app-due-date-label :due-date="item.due_date" />
                </template> -->
                <template v-slot:[`item.status`]="{ item }">
                  <bill-payable-status-chip :value="item.status" />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="justify-end py-4"
          style="background-color: #f5f5f5"
        >
          <!-- <v-btn
            text
            class="text-capitalize"
            color="error"
            @click="handleCancel()"
            >Cancelar Lançamento</v-btn
          > -->
          <v-btn
            @click="dialog = false"
            text
            class="text-capitalize"
            color="secondary"
            >Voltar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import AccountLabel from "@/components/account/sections/AccountLabel.vue";
import BillPayableStatusChip from "@/components/bill-payable/sections/BillPayableStatusChip.vue";

export default {
  components: { AccountLabel, BillPayableStatusChip },
  data: () => ({
    dialog: false,
    id: null,
    billPayable: {
      company: {},
      account_plan: {},
      bill_payable_payments: [],
    },
    headers: [
      { text: "Conta", value: "account_transaction.account.name" },
      { text: "Data do Pg", value: "paid_at" },
      { text: "Valor Pago", value: "paid_value", align: "right" },
      { text: "Desconto", value: "discount", align: "right" },
      { text: "Acréscimo", value: "addition", align: "right" },
      { text: "Juros", value: "interest", align: "right" },
      { text: "Valor Líquido Pg.", value: "net_paid_value", align: "right" },
      { text: "Status", value: "status", align: "center" },
    ],
  }),

  methods: {
    async open(id) {
      this.id = id;
      await this.loadBillPayable();
      this.dialog = true;
    },

    async loadBillPayable() {
      let response = await this.$http.show(
        "bill-payable/bill-payable",
        this.id
      );

      this.billPayable = response.billPayable;
    },
  },
};
</script>

<style>
</style>