<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1600px"
    origin="left top"
    class="pa-md-8"
  >
    <v-card>
      <v-card-title> Pagar Conta a Pagar </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="pr-8" cols="8">
            <template v-for="(row, index) in form.billPayables">
              <v-row class="mt-4 align-center" :key="index">
                <v-col md="4">
                  <template v-if="row.bill.supplier">
                    <b>{{ row.bill.supplier.name }}</b>
                    <template v-if="row.bill.description">
                      <br />
                      <small>{{ row.bill.description }}</small>
                    </template>
                  </template>
                  <template v-else>
                    <b>{{ row.bill.description }}</b>
                  </template>
                </v-col>
                <v-col class="d-flex" md="8">
                  <app-number-field
                    label="Saldo Devedor"
                    :value="row.ramainder_value"
                    readonly
                    background-color="#f5f5f5"
                  />
                  <app-number-field label="Pagar" v-model="row.add_payment" />
                  <app-number-field
                    label="Desconto"
                    v-model="row.add_discount"
                  />
                  <app-number-field
                    label="Acréscimo"
                    v-model="row.add_addition"
                  />
                  <app-number-field label="Juros" v-model="row.add_interest" />
                  <app-number-field
                    label="Valor Final"
                    :value="getNetToPay(row)"
                    readonly
                  />
                </v-col>
              </v-row>
              <v-divider :key="'divider' + index" />
            </template>
            <!-- <v-row class="mt-6">
              <v-col md="4" offset="8">
                <account-select
                  v-model="form.account"
                  label="Selecionar caixa"
                />
              </v-col>
            </v-row> -->
          </v-col>
          <v-col class="pl-8" cols="4">
            <v-card color="grey lighten-3 " elevation="0">
              <v-card-text>
                <div
                  class="
                    dashed-bottom-border
                    pb-1
                    mb-4
                    d-flex
                    justify-space-between
                  "
                >
                  <div>
                    <v-icon class="mr-4">mdi-equal</v-icon> Saldo
                    Devedor
                  </div>
                  <div>
                    <b>{{ $format.decimal(sum_add_payment) }}</b>
                  </div>
                </div>
                <div
                  class="
                    dashed-bottom-border
                    pb-1
                    mb-4
                    d-flex
                    justify-space-between
                  "
                >
                  <div><v-icon class="mr-4">mdi-currency-usd-circle-outline</v-icon> Pagar</div>
                  <div>
                    <b>{{ $format.decimal(sum_add_payment) }}</b>
                  </div>
                </div>
                <div
                  class="
                    dashed-bottom-border
                    pb-1
                    mb-4
                    d-flex
                    justify-space-between
                  "
                >
                  <div>
                    <v-icon class="mr-4">mdi-brightness-percent</v-icon> Desconto
                  </div>
                  <div>
                    <b>{{ $format.decimal(sum_add_discount) }}</b>
                  </div>
                </div>
                <div
                  class="
                    dashed-bottom-border
                    pb-1
                    mb-4
                    d-flex
                    justify-space-between
                  "
                >
                  <div><v-icon class="mr-4">mdi-percent-outline</v-icon> Juros</div>
                  <div>
                    <b>{{ $format.decimal(sum_add_interest) }}</b>
                  </div>
                </div>
                <div
                  class="
                    dashed-bottom-border
                    pb-1
                    mb-4
                    d-flex
                    justify-space-between
                  "
                >
                  <div>
                    <v-icon class="mr-4">mdi-plus</v-icon> Acréscimo
                  </div>
                  <div>
                    <b>{{ $format.decimal(sum_add_addition) }}</b>
                  </div>
                </div>

                <div class="text-right mt-8">
                  <span class="text--disabled">Valor A Pagar </span>
                  <h1 class="mt-3">R$ {{ $format.decimal(sum_net_paid_value) }}</h1>
                </div>
                <div class="mt-8">
                 <app-text-field
                  outlined
                  v-model="form.paid_at"
                  type="date"
                  label="Data do Pagamento"
                />
                </div>
                <div class="mt-4">
                  <account-select
                    :allowed-only="true"
                    v-model="form.account"
                    label="Selecionar caixa"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handlePay()" class="text-capitalize" color="primary"
          >Pagar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccountSelect from "@/components/account/ui/AccountSelect.vue";
import format from "date-fns/format";

export default {
  components: { AccountSelect },

  data() {
    return {
      dialog: false,

      form: {
        account: null,
        paid_at: format(new Date(), "yyyy-MM-dd"),
        billPayables: [],
      },
    };
  },

  computed : {
    sum_add_payment() {
      return this.form.billPayables.reduce((sum, row) => sum + row.add_payment, 0);
    },

    sum_add_discount() {
      return this.form.billPayables.reduce((sum, row) => sum + row.add_discount, 0);
    },

    sum_add_addition() {
      return this.form.billPayables.reduce((sum, row) => sum + row.add_addition, 0);
    },

    sum_add_interest() {
      return this.form.billPayables.reduce((sum, row) => sum + row.add_interest, 0);
    },

    sum_net_paid_value() {
      return (
        this.sum_add_payment -
        this.sum_add_discount +
        this.sum_add_addition +
        this.sum_add_interest
      );
    },
  },

  methods: {
    open(bills) {
      this.reset();

      this.dialog = true;

      this.setBillPayables(bills);
    },

    reset() {
      this.form = {
        account: null,
        billPayables: [],
        paid_at: format(new Date(), "yyyy-MM-dd"),
      };
    },

    setBillPayables(bills) {
      for (let index in bills) {
        let billForm = this.getBillFormData(bills[index]);

        this.$set(this.form.billPayables, index, billForm);
      }
    },

    getBillFormData(bill) {
      let billForm = {};

      billForm.id = bill.id;
      billForm.bill = bill;
      billForm.ramainder_value = this.$calc.round(bill.value - bill.paid_value);
      billForm.add_payment = this.$calc.round(bill.value - bill.paid_value);
      billForm.add_discount = 0;
      billForm.add_addition = 0;
      billForm.add_interest = 0;

      return billForm;
    },

    getNetToPay(billForm) {
      return (
        billForm.add_payment -
        billForm.add_discount +
        billForm.add_addition +
        billForm.add_interest
      );
    },

    async handlePay() {
      this.$store.commit("app/showLoading");
      
      await this.$http
        .store("bill-payable/pay-bill-payable", this.form)
        .then((response) => {
          this.dialog = false;
          this.$emit('store')
        }).catch((error) => {

        })
      this.$store.commit("app/hideLoading");
    },
  },
};
</script>
